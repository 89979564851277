import type { Child } from '~~/types/child'
import type { guardChildrenDatas } from '~~/types/guardChildrenDatas'
import type { AppStatusGuardRes } from '~~/types/appStatusGuardRes'
import { convertDivForFront } from '~/libs/convertDivForFront'
import { Schy } from '~/enum/schy'
import apiSchy from '~/const/apiSchy'
import http from '~/http-common'
import { getDefaultAvatarPath } from '~/libs/getDefaultAvatarPath'
import { ApiPath } from '~/enum/apiPath'
import { FreeTrialStatus } from '~/enum/freeTrialStatus'
import { memberAdmissionStatusCheck } from '~/libs/memberAdmissionStatusCheck'
import { MemberAdmissionStatus } from '~/enum/memberAdmissionStatus'
import { defineStore } from 'pinia'
import { ref } from 'vue'

const APP_STATUS_GUARD_STORE_KEY = 'AppStatusGuardStore'

export const useAppStatusGuardStore = defineStore(APP_STATUS_GUARD_STORE_KEY, () => {

  const children = ref<Child[]>([])
  const selectedChild = ref<Child>({
    userId: '',
    flnmFnm: '',
    flnmLnm: '',
    schy: null,
    schyDiv: '',
    orgId: '',
    orgNm: '',
    avatar: getDefaultAvatarPath(''),
    qrcode: '',
    unReadCount: 0,
    disagreeFlg: '',
    freeTrialStatus: FreeTrialStatus.NOT_TRIED,
    memberAdmissionStatus: MemberAdmissionStatus.NOT_MEMBER,
  })

  const setSelectedChild = (child: Child) => {
    selectedChild.value = child
  }

  const resetAppStatusGuard = () => {
    children.value = []
    selectedChild.value = {
      userId: '',
      flnmFnm: '',
      flnmLnm: '',
      schy: null,
      schyDiv: '',
      orgId: '',
      orgNm: '',
      avatar: getDefaultAvatarPath(''),
      qrcode: '',
      unReadCount: 0,
      disagreeFlg: '',
      freeTrialStatus: FreeTrialStatus.NOT_TRIED,
      memberAdmissionStatus: MemberAdmissionStatus.NOT_MEMBER,
    }
  }

  const getAvatar = () => {
    return selectedChild.value.avatar
  }

  const getChildren = () => {
    return children.value
  }

  const getFirstChild = () => {
    return children.value[0]
  }

  const getSelectedChild = () => {
    return selectedChild.value
  }

  const getMemberAdmissionStatus = () => {
    return selectedChild.value.memberAdmissionStatus
  }

  /**
   * guard/F30420/initへのGETを行う
   * @params noticeId
   * @returns HttpResponseData
   */
  const fetchChildren = async (): Promise<AppStatusGuardRes> => {
    const headers = {
      mode: 'cors',
      credentials: 'include',
      csrfToken: sessionStorage.getItem('token')!,
    }
    const path = ApiPath.APP_STATUS_GUARD_MODULE_LOAD_CHILDREN
    return http.get(path, { headers: headers })
  }

  const loadChildren = async(): Promise<AppStatusGuardRes> => {
    // API実行
    const result: AppStatusGuardRes = await fetchChildren()
    if (result.data.code == 0) {
      // Storeの初期化
      resetAppStatusGuard()
      // Storeの再設定
      const stuList = result.data.stuList
      stuList.forEach((element: guardChildrenDatas) => {
        const child: Child = {
          userId: element.stuId,
          flnmFnm: element.flnmNm,
          flnmLnm: element.flnmLnm,
          schy: Schy[convertDivForFront(element.schyDiv, apiSchy) as keyof typeof Schy],
          schyDiv: element.schyDiv,
          orgId: element.orgId,
          orgNm: element.orgNm,
          avatar: getDefaultAvatarPath(element.photPath),
          qrcode: element.qrCod,
          unReadCount: element.unReadCount,
          disagreeFlg: element.disagreeFlg,
          freeTrialStatus: FreeTrialStatus[element.freeTrialStatus as keyof typeof FreeTrialStatus],
          memberAdmissionStatus: memberAdmissionStatusCheck(FreeTrialStatus[element.freeTrialStatus as keyof typeof FreeTrialStatus], element.admissionDiv),
        }
        children.value.push(child)
      })
    }
    return result
  }

  return {
    children,
    selectedChild,
    getChildren,
    getSelectedChild,
    getFirstChild,
    getMemberAdmissionStatus,
    setSelectedChild,
    resetAppStatusGuard,
    getAvatar,
    loadChildren,
  }
}, {
  persist: {
    storage: sessionStorage,
  }
})

export const notExistGuardStore = () => {
  return !sessionStorage.getItem(APP_STATUS_GUARD_STORE_KEY)
}
